// @ts-nocheck
import {
  Row,
  Card,
  Col,
  Button,
  InputGroup,
  Container,
  FormControl,
  Form,
  
} from "react-bootstrap";
import "./style.css";

import styled from "styled-components";

import {
  JSXElementConstructor,
  ReactElement,
  ReactFragment,
  ReactPortal,
  useEffect,
  useState,
  
} from "react";


import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";

import { getFunctions, httpsCallable } from "firebase/functions";
// @ts-ignore
import { app } from "../firebase1.ts";
import { FiMapPin } from "react-icons/fi";

import {
  checkOut,
  getExtras,
  getEquipment,
  checkAvailability,
  getMonteringsInfo,
} from "../components/Backend.tsx";

import { IoCheckmark, IoClose } from "react-icons/io5";
import {BsFillCheckCircleFill} from "react-icons/bs"
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker";
import nb from "date-fns/locale/nb";

import ModalComp from "../components/ModalComp.tsx";
import AddOnsForPackage from "../components/AddOnsForPackage.tsx";

import BootstrapSwitchButton from "bootstrap-switch-button-react";

import {
  DocumentData,
  getDoc,
  addDoc,
  doc,
  query,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../firebase1.ts";
import { useNavigate, useParams } from "react-router-dom";
import OrderList from "../components/OrderList.tsx";
import Loading from "../components/Loading.tsx";
import { BiUpsideDown } from "react-icons/bi";
import { TbArrowWaveRightDown } from "react-icons/tb";
import Cookies from "universal-cookie";

function distanceTo(lat1: number, lon1: number, lat2: number, lon2: number) {
  var radlat1 = (Math.PI * lat1) / 180;
  var radlat2 = (Math.PI * lat2) / 180;
  var theta = lon1 - lon2;
  var radtheta = (Math.PI * theta) / 180;
  var dist =
    Math.sin(radlat1) * Math.sin(radlat2) +
    Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = (dist * 180) / Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;
  return Math.round(dist);
}

const Packages = () => {
  let { packageNameId, sDate, eDate } = useParams();
  const packageId = packageNameId;

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const functions = getFunctions(app, 'europe-west1');

  const createVippsLogin = httpsCallable(functions, "createVippsLogin")
  const createVippsCheckout = httpsCallable(functions, "createVippsCheckout")

  const verifyPhoneNumber = httpsCallable(functions, "verifyNumber");
  registerLocale("nb", nb);

  //Modaler
  const [showModal, setShow] = useState(false);
  const [showPickUpModal, setShowPickUp] = useState(false);
  const [showDeliveryModal, setShowDelivery] = useState(false);
  const [showDeliveryCoordModal, setShowDeliveryCoordModal] = useState(false);
  const [showCompleteOrderModal, setShowCompleteOrderModal] = useState(false);
  const [chosePreferredDeliveryTime, setChosePreferredDeliveryTime] =
    useState(false);
  const [activeTab, setActiveTab] = useState("innholdsTab");

  const [nameWhatToDo, setName] = useState("");
  const [InsuranceModal, setInsuranceModal] = useState(false);

  const [authenticatedUser, setAuthenticatedUser] = useState({ 
    name:null,
    phoneNumber:null,
    email: null, 
    birthdate: null, 
  }
)

  // Datepicker - dato håndtering
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [daysBetween, setDaysBetween] = useState(1);
  const [titlePackage, setTitlePackage] = useState("");

  //Tidsintervaller håndtering
  const generateTimeslot = (startHour, endHour, interval) => {
    let times = [];
    let dateIterator = new Date();
    dateIterator.setHours(startHour);
    dateIterator.setMinutes(0);

    while (dateIterator.getHours() < endHour) {
      let start =
        dateIterator.getHours().toString().padStart(2, "0") +
        ":" +
        dateIterator.getMinutes().toString().padStart(2, "0");
      dateIterator.setHours(dateIterator.getHours() + interval);
      let end =
        dateIterator.getHours().toString().padStart(2, "0") +
        ":" +
        dateIterator.getMinutes().toString().padStart(2, "0");
      times.push(start + " - " + end);
    }

    return times;
  };

  let timeSlots = generateTimeslot(10, 20, 2); // generates time slots from 10:00 to 20:00 with 2 hours interval
  const [selectedTimeForDelivery, setSelectedTimeForDelivery] = useState("");
  const [selectedTimeForPickup, setSelectedTimeForPickup] = useState("");

  //Data fra database
  const [description, setDescription] = useState("");
  const [dayPrice, setDayPrice] = useState(0);
  const [insurancePrice, setInsurancePrice] = useState(0);
  const [startPrice, setStartPrice] = useState(0);
  const [addOns, setAddOns] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [montPrice, setMontPrice] = useState(0);
  const [hentingPrice, setHentingPrice] = useState(0);
  const [leveringPrice, setLeveringPrice] = useState(0);
  const [insPrice, setInsPrice] = useState(0);
  const [monteringPrice, setMonteringPrice] = useState(0);
  const [addOnPrice, setAddOnPrice] = useState(0);
  const [extraDayPrice, setExtraDayPrice] = useState(0);
  const [deliveryPrice, setDeliveryPrice] = useState(0);
  const [packageType, setPackageType] = useState("");
  const [imgURL, setImgURL] = useState("https://i0.wp.com/econtrol.no/wp-content/uploads/placeholder-1.png?ssl=1");
  const [monteringsInfo, setMonteringsInfo] = useState([]);

  //States knyttet til metode for henting og levering
  const [collectionMethod, setCollectionMethod] = useState("");
  const [pickUpAdress, setPickUpAdress] = useState("");
  const [pickUpAddressChosen, setPickUpAddressChosen] = useState(false);
  const [potentialDeliveryAdress, setPotentialDeliveryAdress] = useState("");
  const [deliveryAdress, setDeliveryAdress] = useState("");
  const [pickupCoordinates, setPickUpCordinates] = useState({
    lat: 0,
    lng: 0,
  });
  const [closestStorage, setClosestStorage] = useState("");
  const [closestStorageDistance, setClosestStorageDistance] = useState(0);

  //States knyttet til bestilling og pris
  let [totalPrice, setTotalPrice] = useState(0);
  const [insurance, setInsurance] = useState(false);
  const [montering, setMontering] = useState(false);

  //States knyttet til godkjenning av vilkår
  const [terms, setTerms] = useState(false);
  const [termsNotChecked, setTermsNotChecked] = useState(false);

  //States knyttet til rabattkoder og validering
  const [rabattkoder, setRabattkoder] = useState<DocumentData[]>([]);
  const [rabattkodeVisibility, setRabattkodeVisibility] = useState(false);
  const [rabattVerdi, setRabattertVerdi] = useState(0);
  const [inputRabattkode, setInputRabattkode] = useState("");
  const [rabattFeilKode, setRabattFeilKode] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [validationMessage, setValidationMessage] = useState("");

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberChosen, setPhoneNumberChosen] = useState(false);

  const [validateShow, setValidateShow] = useState(false);
  const [verifyInputCode, setVerifyInputCode] = useState(0);
  const [verifyCode, setVerifyCode] = useState(0);
  const [validateFalseShow, setValidateFalseShow] = useState(false);
  const [turnOffInsurance, setTurnOffInsurance] = useState(false);

  // Validating
  const [areFieldsFilledVerifyCode, setAreFieldsFilledVerifyCode] =
    useState("disabled");
  const [areFieldsFilled, setAreFieldsFilled] = useState("disabled");
  const [areDateRangeFilled, setAreDateRangeFilled] = useState(false);

  //Calculate extra day price and daterange based on information in URL
  const startDateFromURL = sDate;
  const endDateFromURL = eDate;
  useEffect(() => {
    if (dayPrice) {
      if (startDateFromURL && endDateFromURL) {
        setDateRange([new Date(startDateFromURL), new Date(endDateFromURL)]);
        calcExtraPricePrDay(
          new Date(startDateFromURL),
          new Date(endDateFromURL)
        );
      }
    }
  }, [startDateFromURL, endDateFromURL, dayPrice]);

  useEffect(() => {
    if (startDate && !endDate) {
      setAreDateRangeFilled(true);
    } else {
      setAreDateRangeFilled(false);
    }
  }, [endDate, dateRange, startDate]);

  useEffect(() => {
    if (verifyInputCode !== 0 && verifyInputCode.toString().length === 6) {
      setAreFieldsFilledVerifyCode("");
    }     
    else {
      setAreFieldsFilledVerifyCode("disabled");
    }
  }, [verifyInputCode, authenticatedUser]);

  useEffect(() => {
    if (startDate && endDate && collectionMethod && phoneNumber.length === 8) {
      setAreFieldsFilled("");
    } 
    else if (authenticatedUser.name 
      && authenticatedUser.phoneNumber
      && authenticatedUser.email
      && authenticatedUser.birthdate
      && startDate 
      && endDate
      && nameWhatToDo) 
      {

        setAreFieldsFilled("")

      }
    else {
      setAreFieldsFilled("disabled");
    }
  }, [startDate, endDate, nameWhatToDo, phoneNumber, authenticatedUser, collectionMethod]);

  //Show and hide modaler
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleClosePickUpModal = () => setShowPickUp(false);
  const handleCloseDeliveryModal = () => setShowDelivery(false);
  const handleCloseDeliveryCoordModal = () => setShowDeliveryCoordModal(false);
  const handleCloseInsuranceModal = () => setShowCompleteOrderModal(false);
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const pointer = {
    cursor: "pointer",
  };

  const CustomButton = styled.button`
    background-color: ${(props) =>
      props.activeTab === props.correctTab ? "#212529" : "#989FA5"};
    color: white;
    padding: 15px 15px 15px 15px;
    border-radius: 0.375rem;
  `;

  const [showCheckAvailabilityModal, setCheckAvailabilityModal] =
    useState(false);
  const handleCloseCheckAvabilityModal = () => setCheckAvailabilityModal(false);

  let [caAddons, setCaAddons] = useState([]);

  const fetchDeliveryServices = async () => {
    const serviceMapping = {
      montering: setMontPrice,
      levering: setLeveringPrice,
      henting: setHentingPrice,
    };

    const q = query(collection(db, "deliveryService"));
    const data = await getDocs(q);

    data.forEach((doc: { data: () => any }) => {
      let objectData = doc.data();

      if (objectData.id in serviceMapping) {
        serviceMapping[objectData.id](objectData.pris);
      }
    });
  };

  const [inputStylePhone, setInputStylePhone] = useState("gray");
  const [inputStyleRentPeriode, setInputStyleRentPeriode] = useState("gray");
  const [inputStyleDelivery, setInputStyleDelivery] = useState("gray");

  const packageData = {
    soundboks3: ["Soundboks 3", "SOUNDBOKS 3"],
    kraftiglydpakke: ["Kraftig lydpakke", "KRAFTIG LYDPAKKE"],
    ordinarlydpakke: ["Ordinær lydpakke", "ORDINÆR LYDPAKKE"],
    "kraftiglydpakke-ekstrabass": [
      "Kraftig lydpakke-ekstra bass",
      "KRAFTIG LYDPAKKE MED EKSTRA BASS",
    ],
    effektpakke: ["Effektpakke", "EFFEKTPAKKE"],
    mediumpartypakke: ["Medium Partypakke", "MEDIUM PARTYPAKKE"],
    storpartypakke: ["Stor Partypakke", "STOR PARTYPAKKE"],
    stemningspakke: ["Stemningspakke", "STEMNINGSPAKKE"],
    talepakke: ["Talepakke", "TALEPAKKE"],
    showpakke: ["Showpakke", "SHOWPAKKE"],
    bryllupspakke: ["Bryllupspakke", "BRYLLUPSPAKKE"],
    bord: ["Bord", "BORD"],
    stoler: ["Stoler", "STOLER"],
    partytelt: ["Partytelt", "PARTYTELT"],
  };

  const fetchData = async () => {
    if (packageId in packageData) {
      const [packageName, titlePackage] = packageData[packageId];
      const docRef = doc(db, "packages", packageName);
      const extra = await getExtras(packageName);
      const equipment = await getEquipment(packageName);
      const monteringsInfo = await getMonteringsInfo(packageName);

      setPackageType(packageName);
      setTitlePackage(titlePackage);

      /* const filteredAddOns = extra.filter(addOn => addOn.id !== 'kabelHvit');  
      setAddOns(filteredAddOns); */
      setAddOns(extra);

      setEquipment(equipment);
      setMonteringsInfo(monteringsInfo);

      try {
        const doc = await getDoc(docRef);
        const { startPris, beskrivelse, dagsPris, forsikring, bilde } =
          doc.data();
        setStartPrice(startPris);
        setDescription(beskrivelse);
        setDayPrice(dagsPris);
        setInsurancePrice(forsikring);
        setTotalPrice(startPris + dagsPris);
        setImgURL(bilde);
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const fetchRabattkode = async () => {
    const list: SetStateAction<DocumentData[]> = [];
    const q = query(collection(db, "discount"));
    try {
      const doc = await getDocs(q);
      doc.forEach((doc) => {
        list.push({ id: doc.id, ...doc.data() });
      });
      await setRabattkoder([...list]);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData();
    fetchDeliveryServices();
    fetchRabattkode();
  }, []);

  useEffect(() => {
    calculateMonteringPrice(montering);
  }, [montering]);

  useEffect(() => {
    totalPriceCalc();
  }, [
    addOnPrice,
    extraDayPrice,
    insPrice,
    startPrice,
    rabattVerdi,
    deliveryPrice,
    monteringPrice,
    insurance,
  ]);

  useEffect(() => {
    if (pickUpAdress !== "") {
      setPickUpAddressChosen(false);
    }
  }, [pickUpAdress]);

  useEffect(() => {
    if (phoneNumber !== "") {
      setPhoneNumberChosen(false);
    }
  }, [phoneNumber]);

  function verifyNumber(number: string) {
    let phoneNumber = number;
    let data = {
      tlf: phoneNumber,
    };
    verifyPhoneNumber(data).then((response) => {
      // @ts-ignore
      setVerifyCode(response.data);
    });
  }

  const handleChange =
    (setter: (v: boolean) => void) =>
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setter(e.target.checked);
    };

  const checkRabattkode = () => {
    if (inputRabattkode === "") {
      setRabattertVerdi(0);
      setIsValid(null);
      setValidationMessage("");
      return;
    }

    let isValidCode = false;
    let value = addOnPrice + extraDayPrice + startPrice;

    rabattkoder.map((rabattkode) => {
      if (inputRabattkode === rabattkode.id) {
        isValidCode = true;
        if (rabattkode.value < 1 && rabattkode.valid > 0) {
          setRabattertVerdi(Math.ceil(rabattkode.value * value));
        } else if (rabattkode.value > 1 && rabattkode.valid > 0) {
          setRabattertVerdi(rabattkode.value);
        } else {
          setRabattFeilKode("Ugyldig rabattkode");
        }
      }
    });

    setIsValid(isValidCode);

    if (isValidCode) {
      setValidationMessage("");
    } else {
      setValidationMessage(
        "Ugyldig kode. Husk forskjell på store og små bokstaver."
      );
      setTimeout(() => {
        setIsValid(null);
        setValidationMessage("");
      }, 3000); // Duration of the invalid state animation in milliseconds
    }
  };

  const checkVerifyCode = () => {
    if (verifyCode === verifyInputCode) {
      setValidateShow(false);
      setLoading(true);
      handleOrder(
        phoneNumber,
        packageType,
        pickUpAdress,
        closestStorage,
        insurance,
        startDate,
        endDate,
        {
          henting: false,
          levering: false,
          montering: false,
        }
      );
    } else {
      setValidateShow(false);
      setValidateFalseShow(true);
    }
  };

  async function checkAvaliabilityOfPackage() {
    const allProductsAdded = addOns.concat(equipment);
    console.log("All products: ", allProductsAdded)
    console.log("Extras: ", addOns)

    const combinedProducts = allProductsAdded.reduce((acc, currProduct) => {
      const existingProduct = acc.find(
        (product) => product.id === currProduct.id
      );

      if (existingProduct) {
        existingProduct.count += currProduct.count;
      } else {
        acc.push({ ...currProduct });
      }

      return acc;
    }, []);

    let caAddons = {};
    combinedProducts.forEach((a) => (caAddons[a.id] = parseInt(a.count)));
    console.log("All combined products: ", caAddons)


    let ca = await checkAvailability(
      caAddons,
      pickUpAdress,
      startDate,
      endDate
    );

    setCaAddons(ca);

    if (ca.available === false) {
      const today = new Date();
      const formattedDate = today.getDate() + '.' + (today.getMonth() + 1) + '.' + today.getFullYear();
    
      await addDoc(collection(db, "demand"), {
        lager: pickUpAdress,
        utstyrUtilgjengelig: ca.gearUnavailable,
        dateRequested: formattedDate, 
      })
      .then(setCheckAvailabilityModal(true))
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }

    if (ca.available === true) {
      return gatherAllInformation();
    }
  }

  async function handleOrder(
    phoneNumber: any,
    packageType: any,
    pickUpAdress: any,
    closestStorage: any,
    insurance: any,
    endDate: any,
    startDate: any
  ) {
    const deliveryServiceBookCheck = {
      levering: {
        order: deliveryPrice === leveringPrice,
        tidspunkt: selectedTimeForDelivery,
      },
      henting: {
        order: deliveryPrice === leveringPrice + hentingPrice,
        tidspunkt: selectedTimeForPickup,
      },
      montering: {
        order: monteringPrice === montPrice && collectionMethod === "Levering",
      },
      adresse: deliveryAdress,
    };

    await localStorage.setItem("totalprice", totalPrice);
    await localStorage.setItem("totalAddonPrice", addOnPrice);

    const relevantStorage = pickUpAdress || closestStorage;

    const rightFormatAddOns = Object.fromEntries(
      addOns.map((a) => [a.id, parseInt(a.count)])
    );

   

    const orderId = await checkOut(
      phoneNumber,
      packageType,
      relevantStorage,
      insurance,
      endDate,
      startDate,
      inputRabattkode,
      {
        ...deliveryServiceBookCheck,
        adresse: deliveryAdress,
      },
      rightFormatAddOns
    );

    navigate(`/order/${orderId}`);
  }

  //Regner ut ekstra dagspris
  function calcExtraPricePrDay(startDate: Date, endDate: Date) {
    let exRentDays = 0;
    let daysBetween = Math.round(
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
    );
    setDaysBetween(daysBetween);
    if (daysBetween === 0) {
      setExtraDayPrice(0);
      totalPriceCalc();
    }
    exRentDays =
      Math.round(
        (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
      ) * dayPrice;
    setExtraDayPrice(exRentDays);
    return extraDayPrice;
  }

  useEffect(() => {
    calcAddOnPrice();
  }, [daysBetween]);

  function calcAddOnPrice() {
    let addOnPrice: number = 0;
    let dayPrice: number = 0;
    console.log("calcAddOn kjører: ")

    Object.keys(addOns).forEach(function (key) {
      if (addOns[key].count !== 0 && !isNaN(addOns[key].count)) {
        addOnPrice += addOns[key].startPris * addOns[key].count;

        dayPrice += addOns[key].dagsPris * daysBetween * addOns[key].count;

      } else if (isNaN(addOns[key].count)) {
        addOnPrice += addOns[key].startPris * 0;
        dayPrice += addOns[key].dagsPris * 0;
      }
    });

    setAddOnPrice(addOnPrice + dayPrice);
    return addOnPrice;
  }

  function calculateInsurancePrice(insuranceTrue: boolean) {
    if (insuranceTrue === true) {
      setInsPrice(insurancePrice);
    }

    if (insuranceTrue === false) {
      setInsPrice(0);
    }
    return insurancePrice;
  }

  function calculateMonteringPrice(monteringToggle: boolean) {
    if (monteringToggle === true) {
      setMonteringPrice(montPrice);
    } else {
      setMonteringPrice(0);
    }
    return montPrice;
  }

  const handleAddress = async (e: any) => {
    const results = await geocodeByAddress(e);
    const latLng = await getLatLng(results[0]);
    setPickUpCordinates(latLng);
  };

  function totalPriceCalc() {
    const price =
      addOnPrice +
      startPrice +
      insPrice +
      extraDayPrice +
      deliveryPrice +
      monteringPrice -
      rabattVerdi;
    return setTotalPrice(price);
  }

  const checkDistanceToPickup = (lat, lon, address) => {
    const storageUnits = [
      {
        name: "Lørenskog",
        lat: 59.937339897357525,
        lon: 10.974654223440327,
        maxDistance: 20,
      },
      /*
      {
        name: "Trondheim",
        lat: 63.40649400343276,
        lon: 10.418490147036561,
        maxDistance: 20,
      },*/
             {
        name: "Vinterbro",
        lat: 59.70188425922689,
        lon: 10.758360444768606,
        maxDistance: 15,
      },

             {
        name: "Asker",
        lat: 59.8325935,
        lon: 10.4395419,
        maxDistance: 15
      },
    ];

    let closestStorage = storageUnits.reduce((prev, current) => {
      const prevDistance = distanceTo(lat, lon, prev.lat, prev.lon);
      const currDistance = distanceTo(lat, lon, current.lat, current.lon);
      return prevDistance < currDistance ? prev : current;
    });

    const closestDistance = distanceTo(
      lat,
      lon,
      closestStorage.lat,
      closestStorage.lon
    );

    setShowDelivery(false);
    setShowDeliveryCoordModal(true);

    if (closestDistance > 20) {
      setDeliveryPrice(0);
      setCollectionMethod("");
    } else {
      let cleanedName = closestStorage.name.toLowerCase().replace("ø", "o");
      setClosestStorage(cleanedName);
      setPickUpAdress(cleanedName);
      setDeliveryAdress(potentialDeliveryAdress);
      setCollectionMethod("Levering");
    }

    setClosestStorageDistance(closestDistance);
  };

  const handleTurnOffInsuranseYes = () => {
    setShowCompleteOrderModal(true);
    setInsurance(false);
    setTurnOffInsurance(false);
    setInsPrice(0);
  };

  const handleToogle = (e: boolean) => {
    if (e === false) {
      setShowCompleteOrderModal(false);
      setTurnOffInsurance(true);
    } else {
      setInsurance(e);
      calculateInsurancePrice(e);
    }
  };

  const handleToogleMontering = (e: boolean) => {
    setMontering(e);
  };

  const handleChosenDelivery = (e: string) => {

    if (e === "pickUp") {
      handleClose();
      setShowPickUp(true);
      setShowDelivery(false);
      setCollectionMethod("Hente selv");
      setDeliveryAdress("");

    } else if (totalPrice < 500) {
      setLeveringPrice(hentingPrice);
      handleClose();
      setShowPickUp(false);
      setShowDelivery(true);
    } else {
      handleClose();
      setShowPickUp(false);
      setShowDelivery(true);
    }
  };

  const handleChosenPickUpPlace = (e: string) => {
    setPickUpAdress(e);
    handleClosePickUpModal();
  };

  const placeOrderButton = () => {
    if (!terms) {
      setTermsNotChecked(true);
    } else {
      setTermsNotChecked(false);
      verifyNumber(phoneNumber ? phoneNumber : authenticatedUser.phoneNumber.slice(2));
      setInsuranceModal(false);
      setValidateShow(true);
    }
  };

  const gatherAllInformation = () => {
    setInsurance(true);
    setInsPrice(insurancePrice);
    const testing = /^[0-9\b]+$/;
    if (
      (!phoneNumber ||
      !testing.test(phoneNumber) ||
      phoneNumber.length !== 8) 
    ) {
      setInputStylePhone("red border-2");
      setPhoneNumberChosen(true);
    } else {
      setInputStylePhone("gray");
      setPhoneNumberChosen(false);
    }

    if (collectionMethod === "Hente selv" || deliveryAdress) {
      setShowCompleteOrderModal(true);
    }
  };
  /* LOADING
        <div className="bg-white flex justify-content-center align-items-center text-align-center ">
          <Row className="md:mt-40 mt-20 mb-20">
            <Col>
              <Loading color={`#e57e22`} />
            </Col>
          </Row>
        </div>
*/

const storageUnits = [
  {
    name: "Lørenskog",
    address: "Oscar Braatens vei 6, 1472 Fjellhamar",
    lat: 59.937339897357525,
    lon: 10.974654223440327,
    mapLink: "https://www.google.com/maps/search/?api=1&query=59.937339897357525,10.974654223440327",
  },
  {
    name: "Asker",
    address: "Bleikerveien 15, 1387 Asker",
    lat: 59.8325935,
    lon: 10.4395419,
    mapLink: "https://www.google.com/maps/search/?api=1&query=59.8325935,10.4395419",
  },
  {
    name: "Vinterbro",
    address: "Sundbyveien 90, 1407 Vinterbro",
    lat: 59.70188425922689,
    lon: 10.758360444768606,
    mapLink: "https://www.google.com/maps/search/?api=1&query=59.70188425922689,10.758360444768606",
  },
  {
    name: "Trondheim",
    address: "Sørenga 9, 7032 Trondheim",
    lat: 63.40649400343276,
    lon: 10.418490147036561,
    mapLink: "https://www.google.com/maps/search/?api=1&query=63.40649400343276,10.418490147036561",
  },
  {
    name: "Stavanger",
    address: "Haugåsstubben 14, 4016 Stavanger",
    lat: 58.94556836897725,
    lon: 5.725300015090888,
    mapLink: "https://www.google.com/maps/search/?api=1&query=58.94556836897725,5.725300015090888",
  },
  {
    name: "Kristiansand",
    address: "Rigedalen 49, 4626 Kristiansand",
    lat: 58.1389865,
    lon: 7.9263283,
    mapLink: "https://www.google.com/maps/search/?api=1&query=58.1389865,7.9263283",
  }, 
  {
    name: "Bergen",
    address: "Spelhaugen 18, 5147 Fyllingsdalen",
    lat: 60.3545537,
    lon: 5.2511893,
    mapLink: "https://www.google.com/maps/search/?api=1&query=60.3545537,5.2511893",
  }
  // ... Legg til flere lagersteder om nødvendig ...
];

const [sortedWarehouses, setSortedWarehouses] = useState(storageUnits);

const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      },
      (error) => {
        reject(error);
      }
    );
  });
};

const sortWarehousesByDistance = async () => {
  try {
    const userLocation = await getUserLocation();
    const sorted = storageUnits.map(storage => {
      const distance = distanceTo(userLocation.lat, userLocation.lng, storage.lat, storage.lon);
      return {
        ...storage,
        distance,
        distanceText: `${distance.toFixed(0)} km fra deg`
      };
    }).sort((a, b) => a.distance - b.distance);

    setSortedWarehouses(sorted);
  } catch (error) {
    console.error("Kunne ikke hente brukerens posisjon:", error);
    // Lagersteder forblir usortert om brukerens posisjon ikke kan hentes
  }
};

    if (nameWhatToDo === "Hente selv") {
      if (pickUpAdress === "") {
        setInputStyleDelivery("red border-2");
        setPickUpAddressChosen(true);
      } else {
        setInputStyleDelivery("gray");
        setPickUpAddressChosen(false);
      }
      if (
        chosenDelivery &&
        pickUpAdress &&
        startDate &&
        endDate &&
        (phoneNumber || authenticatedUser.phoneNumber) &&
        (phoneNumber.length === 8 || authenticatedUser.phoneNumber.slice(2).length === 8)
      ) {
        setInsuranceModal(true);
      }
    }
// Kall på sortWarehousesByDistance når komponenten blir lastet/mounted
useEffect(() => {
  sortWarehousesByDistance();
}, []);

// Modalens innhold med dynamisk genererte lagersteder
const modalBody = (
  <>
    <div className="pr-6 mb-2">
      Hvor ønsker du å hente din bestilling? Velg et av våre selvbetjente lagre under:
    </div>
    {sortedWarehouses.map((warehouse, index) => (
      <Row key={index} className="my-2 lg:mx-10">
        <Col lg={8} sm={12} className="flex flex-col sm:flex-row items-center justify-between hover:bg-gray-100 transition-colors p-3 rounded-lg w-full">
          <div className="mb-2 sm:mb-0 flex-grow">
            <Button
              variant="link"
              className="font-semibold text-gray-800 hover:text-gray-900 transition-colors w-full text-left"
              onClick={() => handleChosenPickUpPlace(warehouse.name.toLowerCase().replace('ø', 'o'))}
            >
              {warehouse.name}
            </Button>
            <p className="text-sm text-gray-600 w-full text-left">
              {warehouse.address}
            </p>
            {warehouse.distanceText && (
              <p className="text-sm text-gray-500">
                {warehouse.distanceText}
              </p>
            )}
          </div>
          <Button
            variant="link"
            className="text-pl_orange hover:text-pl_yellow transition-colors flex items-center whitespace-nowrap"
            onClick={() => window.open(warehouse.mapLink)}
          >
            Se på kart <FiMapPin />
          </Button>
        </Col>
      </Row>
    ))}
  </>
);

  return (
    <div className="bg-[#EBECF0]">

        <>
          <Container>
            <Row>
              <Col lg={{ span: 6, offset: 1 }} className="pt-10 font-josefin">
                <Col>
                  <div className="flex">
                    <div className="font-bold text-3xl tracking-wider pb-8 pl-4 lg:pl-0">
                      LEIE {titlePackage}{" "}
                    </div>
                  </div>
                </Col>
                <Col className="lg:px-0 px-2">
                  <Card className="pt-4 rounded-xl ">
                    <Card.Img
                      variant="top"
                      src={imgURL}
                      className="md:px-12"
                      style={{
                        display: "block",
                        marginLeft: "auto",
                        marginRight: "auto",
                        objectFit: "contain",
                        borderRadius: "15px"
                      }}
                    />
                    <Card.Title className="px-6 pt-6">
                      <hr />
                    </Card.Title>
                    <Card.Body>
                      <Card.Text className="px-6 pt-1 pb-4">
                      {loading ? (<Loading color={"#e57e22"} />):""}  
               {description}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col className="lg:px-0 px-2">
                  <Row>
                    <Col>
                      <div className="pb-8 pl-4 mt-8">
                        <CustomButton
                          onClick={() => handleTabClick("innholdsTab")}
                          activeTab={activeTab}
                          correctTab="innholdsTab"
                        >
                          INNHOLD I PAKKEN
                        </CustomButton>
                      </div>
                    </Col>
                    <Col>
                      <div className="pb-8 pl-4 mt-8">
                        <CustomButton
                          onClick={() => handleTabClick("monteringsTab")}
                          activeTab={activeTab}
                          correctTab="monteringsTab"
                        >
                          MONTERING
                        </CustomButton>
                      </div>
                    </Col>
                  </Row>

                  {equipment && activeTab === "innholdsTab" ? (
                    <div>
                      <Row>
                        <Col>
                          <Card className="rounded-xl pt-2 px-1">
                          {loading ? (<Loading color={"#e57e22"} />):""}
                            {equipment.map((p) => (
                              <>
                                <div
                                  //@ts-ignore
                                  key={p.id}
                                >
                                  <div>
                                    <OrderList
                                      //@ts-ignore
                                      bilde={p.bilde}
                                      //@ts-ignore
                                      beskrivelse={p.beskrivelse}
                                      //@ts-ignore
                                      navn={p.navn}
                                      //@ts-ignore
                                      count={p.count}
                                    />
                                  </div>
                                </div>
                              </>
                            ))}
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {monteringsInfo && activeTab === "monteringsTab" ? (
                    <div>
                      <Row>
                        <Col>
                          <Card className="rounded-xl pt-2 px-1">
                            <ul className="m-6">                
                              {monteringsInfo.map((item, index) => (
                                <li className="mb-3" key={index}>
                                  {" "}
                                  {item.beskrivelse}{" "}
                                </li>
                              ))}
                            </ul>
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </Col>

                <Col className="lg:px-0 px-2">
                  <div className="font-medium text-2xl tracking-wider pb-8 pt-8 pl-4 lg:pl-0">
                    {" "}
                    LEGG TIL TILLEGGSPRODUKTER
                    <Row>
                      <div className="pt-4 font-normal text-sm tracking-wider">
                        Du kan også legge til mer utstyr på din side etter
                        fullført bestilling.
                      </div>
                    </Row>
                  </div>

                  {addOns ? (
                    <div className="pb-8">
                      <Row>
                        <Col>
                          <Card className="rounded-xl pt-2 px-1">
                          {loading ? (<Loading color={"#e57e22"} />):""}
                            {addOns.filter(addOn => addOn.id !=='kabelHvit').map((products) => (
                              <>
                                {products.count !== 0 ? (
                                  <div
                                    //@ts-ignore

                                    key={products.id}
                                  >
                                    <div className="">
                                      <AddOnsForPackage
                                        //@ts-ignore
                                        image={products.bilde}
                                        //@ts-ignore
                                        desc={products.beskrivelse}
                                        //@ts-ignore
                                        name={products.navn}
                                        //@ts-ignore
                                        max={products.maks}
                                        //@ts-ignore
                                        priceFrom={
                                          //@ts-ignore
                                          isNaN(
                                            products.startPris *
                                              products.count +
                                              products.dagsPris *
                                                daysBetween *
                                                products.count
                                          )
                                            ? products.startPris +
                                              products.dagsPris * daysBetween
                                            : products.startPris *
                                                products.count +
                                              products.dagsPris *
                                                daysBetween *
                                                products.count
                                        }
                                        onChange={(e: any) => {
                                          let value = parseInt(e.target.value);

                                          //@ts-ignore
                                          products.count = value;

                                          if(products.id === "mikrofonKablet") {

                                            const kabelHvitIndex = addOns.findIndex(product => product.id === "kabelHvit");
                                            if (kabelHvitIndex !== -1) {
                                              // Opprett en kopi av addOns for å unngå direkte mutasjon
                                              const newAddOns = [...addOns];
                                              // Oppdater "count"-verdien for "kabelHvit" til å matche "mikrofonKablet"
                                              if (e.target.value === "+"  || e.target.value === "Legg til") {
                                                //@ts-ignore
                                                console.log("Target value === legg til")
                                                products.count = 0;
                                              } else {
                                                newAddOns[kabelHvitIndex].count = value;
                                              }
                                              // Oppdater tilstanden
                                              setAddOns(newAddOns);
                                            }
                                          }

                                          if (e.target.value === "+"  || e.target.value === "Legg til") {
                                            //@ts-ignore
                                            console.log("Target value === legg til")
                                            products.count = 0;
                                          }
                                          calcAddOnPrice();
                                        }}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  <div
                                    //@ts-ignore

                                    key={products.id}
                                  >
                                    <div className="">
                                      <AddOnsForPackage
                                        //@ts-ignore
                                        image={products.bilde}
                                        //@ts-ignore
                                        desc={products.beskrivelse}
                                        //@ts-ignore
                                        name={products.navn}
                                        //@ts-ignore
                                        max={products.maks}
                                        //@ts-ignore
                                        priceFrom={
                                          //@ts-ignore
                                          isNaN(
                                            products.startPris +
                                              products.dagsPris * daysBetween
                                          )
                                            ? products.startPris +
                                              products.dagsPris * daysBetween
                                            : products.startPris +
                                              products.dagsPris * daysBetween
                                        }
                                        onChange={(e: any) => {
                                          let value = parseInt(e.target.value);
                                          //@ts-ignore
                                          products.count = value;

                                          if(products.id === "mikrofonKablet") {

                                            const kabelHvitIndex = addOns.findIndex(product => product.id === "kabelHvit");
                                            if (kabelHvitIndex !== -1) {
                                              // Opprett en kopi av addOns for å unngå direkte mutasjon
                                              const newAddOns = [...addOns];
                                              // Oppdater "count"-verdien for "kabelHvit" til å matche "mikrofonKablet"
                                              if (e.target.value === "+"  || e.target.value === "Legg til") {
                                                //@ts-ignore
                                                console.log("Target value === legg til")
                                                products.count = 0;
                                              } else {
                                                newAddOns[kabelHvitIndex].count = value;
                                              }
                                              // Oppdater tilstanden
                                              setAddOns(newAddOns);
                                            }
                                          }

                                          if (e.target.value === "Legg til") {
                                            console.log("Target value === legg til")
                                            //@ts-ignore
                                            products.count = 0;
                                          }
                                          calcAddOnPrice();
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ))}
                          </Card>
                        </Col>
                      </Row>
                    </div>
                    ) : (
                    <div></div>
                  )}
                </Col>
              </Col>
              <Col className="mb-10 font-josefin">
                <Col>
                  <Row>
                    <Col
                      md={{ span: 5, offset: 1 }}
                      xs={{ span: 6, offset: 1 }}
                    >
                      <div>
                        <div className=" lg:pt-10 lg:pb-0 pt-8 pb-2 font-medium text-2xl tracking-wider">
                          {" "}
                          Bestill nå
                          
                        </div>
                        {extraDayPrice !== 0 && daysBetween > 1 ? (
                          <>
                            <div className=" lg:pb-0  font-small text-md text-[#808080]">
                              Grunnpris
                            </div>
                            <div className=" lg:pb-0 font-small text-md text-[#808080]">
                              Ekstra dagspris ({daysBetween - 1} x {dayPrice})
                            </div>
                          </>
                        ) : null}
                        {addOnPrice !== 0 ? (
                          <div className=" lg:pb-0  font-small text-md text-[#808080]">
                            Tilleggsprodukter
                          </div>
                        ) : null}
                        {insurance === true ? (
                          <div className=" lg:pb-0  font-small text-md text-[#808080]">
                            Forsikring
                          </div>
                        ) : null}
                        {deliveryPrice !== 0 ? (
                          <div className=" lg:pb-0  font-small text-md text-[#808080]">
                            Levering
                          </div>
                        ) : null}
                        {monteringPrice !== 0 ? (
                          <div className=" lg:pb-0  font-small text-md text-[#808080]">
                            Montering
                          </div>
                        ) : null}
                      </div>
                    </Col>
                    <Col
                      md={{ span: 4, offset: 1 }}
                      xs={{ span: 4, offset: 1 }}
                    >
                      <div>
                        <div className="lg:pt-10 lg:pb-0 pt-8 pb-2 font-medium text-2xl tracking-wider">
                          {" "}
                          {totalPrice},-
                        </div>
                        {extraDayPrice !== 0 && daysBetween > 1 ? (
                          <>
                            <div className=" lg:pb-0  font-small text-md text-[#808080]">
                              {startPrice + dayPrice} ,-
                            </div>
                            <div className=" lg:pb-0 font-small text-md text-[#808080]">
                              {(daysBetween - 1) * dayPrice} ,-
                            </div>
                          </>
                        ) : null}

                        {addOnPrice !== 0 ? (
                          <div className=" lg:pb-0  font-small text-md text-[#808080]">
                            {addOnPrice} ,-
                          </div>
                        ) : null}

                        {insurance === true ? (
                          <div className=" lg:pb-0  font-small text-md text-[#808080]">
                            {insurancePrice} ,-
                          </div>
                        ) : null}

                        {deliveryPrice !== 0 ? (
                          <div className=" lg:pb-0  font-small text-md text-[#808080]">
                            {deliveryPrice} ,-
                          </div>
                        ) : null}

                        {monteringPrice !== 0 ? (
                          <div className=" lg:pb-0  font-small text-md text-[#808080]">
                            {monteringPrice} ,-
                          </div>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                  <hr
                    style={{
                      display: "block",
                      height: "2px",
                      border: "0",
                      borderTop: "1px solid #ccc",
                      width: "70%",
                      marginLeft: "3em",
                      marginTop: "15px",
                      padding: "0",
                    }}
                  />
                  <Row>
                    {/** Velge leieperiode */}
                    <div className="pl-16 pt-6 font-normal text-sm tracking-wider ">
                      Velg leieperiode
                    </div>
                    <Card
                      className={`text-black bg-white text-left w-8/12 ml-16 mt-3 shadow-md border-${inputStyleRentPeriode}`}
                    >
                      <Card.Body className="pt-2 pr-1 pl-1">
                        <div className="text-xs text-light pb-3 text-black">
                          LEIEPERIODE{" "}
                        </div>
                        <DatePicker
                          selectsRange={true}
                          locale="nb"
                          dateFormat="dd/MM/yyyy"
                          placeholderText="Trykk her for å velge utleieperiode"
                          startDate={startDate}
                          minDate={new Date()}
                          endDate={endDate}
                          className="form-control"
                          onChange={(update) => {
                            //@ts-ignore
                            setInputRabattkode("");
                            setRabattertVerdi(0);
                            setDateRange(update);
                            setExtraDayPrice(0);
                            if (update[1] !== null) {
                              //@ts-ignore

                              calcExtraPricePrDay(update[0], update[1]);
                            }
                          }}
                          isClearable={true}
                        />
                      </Card.Body>
                    </Card>
                    <>
                      <Row className="w-11/12">
                        {startDate && endDate && daysBetween === 0 ? (
                          <div className="pl-16 pt-2 font-medium text-sm tracking-wider text-green-600">
                            Leieutstyr må hentes og leveres samme dag, da sparer
                            du {dayPrice},-
                          </div>
                        ) : null}
                        {areDateRangeFilled === true ? (
                          <div className="pl-16 pt-1 font-normal text-sm tracking-wider text-red-600">
                            Du må velge start- og sluttdato
                          </div>
                        ) : null}
                      </Row>
                    </>
                  </Row>
                  <Row>
                    {/** Velge mellom levering og henting */}
                    <div className="pl-16 pt-8 font-normal text-sm tracking-wider whitespace-pre">
                      Velg hvor du vil hente fra
                      {/* Velg selvbetjening  eller levering */}
                    </div>
                    <Button
                      variant="secondary"
                      className={`text-black bg-white text-left w-8/12 ml-16 mt-3 shadow-md pb-2 mb-1 border-${inputStyleDelivery}`}
                      onClick={handleShow}
                    >
                      {!collectionMethod ? (
                        <>
                          {" "}
                          <div className="text-xs text-light pb-2 text-black">
                            {/* ENDRE TILBAKE LEVERING */}
                            SELVBETJENING{/*  ELLER LEVERING */}
                          </div>
                        </>
                      ) : (
                        <>
                          {
                            <>
                              <div className="text-xs text-light pb-2 text-black">
                                HENTING
                              </div>{" "}
                            </>
                          }
                          {/* ENDRE TILBAKE LEVERING */}
                          {/* {collectionMethod === "Hente selv" ? (
                            <>
                              <div className="text-xs text-light pb-2 text-black">
                                HENTING
                              </div>{" "}
                            </>
                          ) : (
                            <>
                              <div className="text-xs text-light pb-2 text-black">
                                LEVERING
                              </div>{" "}
                            </>
                          )} */}
                        </>
                      )}

                      {collectionMethod ? (
                        <div>
                          {collectionMethod === "Hente selv" ? (
                            <>
                              {pickUpAdress ? (
                                <div>
                                  Du har valgt å hente selv i{" "}
                                  {pickUpAdress === "lorenskog" ? (
                                    <strong>Lørenskog</strong>
                                  ) : (
                                    <>
                                      {pickUpAdress === "Asker" ? (
                                        <strong>Asker</strong>
                                      ) : (
                                        <strong>
                                          {pickUpAdress[0].toUpperCase() +
                                            pickUpAdress.slice(1)}
                                        </strong>
                                      )}
                                    </>
                                  )}
                                </div>
                              ) : null}
                            </>
                          ) : (
                            <>
                              {deliveryAdress ? (
                                <div>
                                  Du har valgt å få levert til{" "}
                                  <strong>{deliveryAdress}</strong>
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>
                      ) : (
                        <div>
                          {/* ENDRE TILBAKE LEVERING */}
                          Trykk for å velge hvor du vil <strong>hente</strong> fra
                          {/* Trykk for å velge <strong>henting</strong> eller{" "}
                          <strong>levering</strong> */}
                        </div>
                      )}
                    </Button>
                    {pickUpAddressChosen === true ? (
                      <div className="pl-16 pt-1 font-normal text-sm tracking-wider text-red-600 pr-16">
                        Du må velge hvilket lager du ønsker å hente på
                      </div>
                    ) : null}

                    {/** Modal for forsikring av*/}
                    <ModalComp
                      show={turnOffInsurance}
                      closeModal={() => setTurnOffInsurance(false)}
                      hide={() => setTurnOffInsurance(false)}
                      title="Ønsker ikke forsikring"
                      footer={
                        <Button
                          variant="secondary"
                          className="text-white bg-[#24292e] text-left"
                          onClick={() => setTurnOffInsurance(false)}
                        >
                          Lukk
                        </Button>
                      }
                      body={
                        <div>
                          <div className="text-sm text-light font-medium pb-2 text-black tracking-wider">
                            Er du sikker på at du ønsker å fjerne forsikring? Da
                            må alle skader dekkes selv.
                          </div>

                          <Row>
                            <Col lg={{ span: 11, offset: 3 }}>
                              <Button
                                variant="primary"
                                className="text-black border-black hover:border-white hover:text-white hover:bg-gray mt-3 w-3/12"
                                onClick={handleTurnOffInsuranseYes}
                              >
                                Ja
                              </Button>

                              <Button
                                variant="secondary"
                                className="text-white bg-[#24292e] mt-3 w-3/12 ml-2"
                                onClick={() => {
                                  setShowCompleteOrderModal(true);
                                  setInsurance(true);
                                  setTurnOffInsurance(false);
                                  setInsPrice(insurancePrice);
                                }}
                              >
                                Nei
                              </Button>
                            </Col>
                          </Row>
                        </div>
                      }
                    />
                    {/** Modal for valg av fortrukket leveringstidspunkt*/}
                    <ModalComp
                      show={chosePreferredDeliveryTime}
                      closeModal={() => setChosePreferredDeliveryTime(false)}
                      hide={() => setChosePreferredDeliveryTime(false)}
                      size="md"
                      title="Tidspunkt for leveringstjenester"
                      footer={
                        <Button
                          variant="secondary"
                          className="text-white bg-[#24292e] text-left"
                          onClick={() => setChosePreferredDeliveryTime(false)}
                        >
                          Lukk
                        </Button>
                      }
                      body={
                        <div>
                          {deliveryPrice === leveringPrice ? (
                            <div className="text-sm text-light font-medium pb-4 text-black tracking-wider">
                              Hvilket tidspunkt ønsker du for levering?
                              <Form.Select
                                aria-label="Time Slot"
                                className="w-6/12"
                                onChange={(e) =>
                                  setSelectedTimeForDelivery(e.target.value)
                                }
                              >
                                <option>Velg tidsintervall</option>
                                {timeSlots.map((slot, index) => (
                                  <option key={index} value={slot}>
                                    {slot}
                                  </option>
                                ))}
                              </Form.Select>
                            </div>
                          ) : (
                            <>
                              <div className="text-sm text-light font-medium pb-4 text-black tracking-wider">
                                Hvilket tidspunkt ønsker du for levering?
                                <Form.Select
                                  aria-label="Time Slot"
                                  className="w-6/12"
                                  onChange={(e) =>
                                    setSelectedTimeForDelivery(e.target.value)
                                  }
                                >
                                  <option>Velg tidsintervall</option>
                                  {timeSlots.map((slot, index) => (
                                    <option key={index} value={slot}>
                                      {slot}
                                    </option>
                                  ))}
                                </Form.Select>
                              </div>
                              <div className="text-sm text-light font-medium pb-2 text-black tracking-wider">
                                Hvilket tidspunkt ønsker du for henting?
                                <Form.Select
                                  aria-label="Time Slot"
                                  className="w-6/12"
                                  onChange={(e) =>
                                    setSelectedTimeForPickup(e.target.value)
                                  }
                                >
                                  <option>Velg tidsintervall</option>
                                  {timeSlots.map((slot, index) => (
                                    <option key={index} value={slot}>
                                      {slot}
                                    </option>
                                  ))}
                                </Form.Select>
                              </div>
                            </>
                          )}
                        </div>
                      }
                    />

                    {/** Modal for å vise utilgjengelig utstyr*/}
                    <ModalComp
                      show={showCheckAvailabilityModal}
                      closeModal={() => setCheckAvailabilityModal(false)}
                      hide={handleCloseCheckAvabilityModal}
                      title="Utstyr ikke tilgjengelig"
                      footer={
                        <Button
                          variant="secondary"
                          className="text-white bg-[#24292e] text-left ml-16 mt-3"
                          onClick={handleCloseCheckAvabilityModal}
                        >
                          Lukk
                        </Button>
                      }
                      body={
                        <div>
                          <div className="text-sm text-light font-bold pb-2 text-black tracking-wider">
                            Følgende utstyr er dessverre ikke tilgjengelig i
                            oppgitt tidsrom på oppgitt lager:
                          </div>
                          {caAddons && caAddons.gearUnavailable ? (
                            caAddons.gearUnavailable.map((gear) => {
                              return (
                                <div className="tracking-wider pb-2">
                                  {`${gear.dateUnavailable} ${gear.gearName}`}
                                </div>
                              );
                            })
                          ) : (
                            <div></div>
                          )}
                        </div>
                      }
                    />
                    {/** Modal for å velge henting eller levering */}
                    <ModalComp
                      show={showModal}
                      closeModal={() => setShow(false)}
                      hide={handleClose}
                      /* ENDRE TILBAKE LEVERING */
                      title="Velg hvor du vil hente selv fra" /* Velg levering eller henting */
                      footer={
                        <Button
                          variant="secondary"
                          className="text-white bg-[#24292e] text-left ml-16 mt-3"
                          onClick={handleClose}
                        >
                          Lukk
                        </Button>
                      }
                      body={
                        <>
                          <div className="pl-8 pr-6 pt-6 mb-2 text-sm">
                            Velg å <strong>hente selv</strong> dersom du
                            vil hente bestillinen på et av våre selvbetjente
                            lagere. Da kan du hente og levere bestillingen når som
                            helst innenfor kalenderdagene du har leid.{" "}
                            Det er <strong>
                             ingen ekstra kostnader
                            </strong> ved å hente selv.
                          </div>
                          <Button
                            variant="secondary"
                            className="text-black bg-white text-left w-10/12 lg:ml-16 ml-8 mt-3 mb-10"
                            onClick={() => {
                              setDeliveryPrice(0);
                              handleChosenDelivery("pickUp");
                            }}
                          >
                            <div className="pb-2 pt-2 text-black">
                              Jeg vil <strong>hente</strong> selv
                            </div>
                          </Button>
                          {/* ENDRE TILBAKE LEVERING */}
                          {/* <h2
                            style={{
                              width: "100%",
                              textAlign: "center",
                              borderBottom: "1px solid #d3d3d3",
                              lineHeight: "0.1em",
                              margin: "10px 0 20px",
                            }}
                          >
                            <span
                              style={{
                                padding: "0 10px",
                                background: "#fff",
                                color: "#808080",
                              }}
                              className="font-normal mt-2"
                            >
                              ELLER
                            </span>
                          </h2>
                          <div className="pl-8 pr-6 pt-8 mb-2 text-sm">
                            Velg <strong>levering</strong> dersom du
                            vil få pakken kjørt til din adresse. Vi kan også bære inn, montere og
                            senere demontere pakken for deg.{" "}
                            Det <strong>
                              koster ekstra
                            </strong>{" "} med henting, bæring og montering.
                            Prisen avhenger av avstanden fra vårt nærmeste lager
                            til din adresse. Vi tilbyr levering i{" "}
                            <strong>Oslo-området</strong>.
                          </div>
                          <Button
                            variant="secondary"
                            className="text-black bg-white text-left w-10/12 lg:ml-16 ml-8 mt-3 mb-10"
                            onClick={() => {
                              handleChosenDelivery("delivery");
                            }}
                          >
                            <div className="pb-2 pt-2 text-black">
                              Jeg ønsker <strong>levering</strong> av utstyr
                            </div>
                          </Button> */}
                        </>
                      }
                    />
                    {/** Modal for å velge hvor du vil hente bestillingen */}
                    <ModalComp
                      show={showPickUpModal}
                      closeModal={() => setShowPickUp(false)}
                      hide={handleClosePickUpModal}
                      title="Hente selv"
                      footer={
                        <Button
                          variant="secondary"
                          className="text-white bg-[#24292e] text-left ml-16"
                          onClick={handleClosePickUpModal}
                        >
                          Lukk
                        </Button>
                      }
                      body={modalBody}
                    />
                    {/* ENDRE TILBAKE LEVERING */}
                    {/** Modal for å skrive inn ønsket leveringsadresse */}
                    {/* <ModalComp
                      show={showDeliveryModal}
                      closeModal={() => setShowDelivery(false)}
                      hide={handleCloseDeliveryModal}
                      title="Leveringsadresse"
                      footer={
                        <Button
                          variant="secondary"
                          className="text-white bg-[#24292e] text-left ml-16 mt-3"
                          onClick={handleCloseDeliveryModal}
                        >
                          Lukk
                        </Button>
                      }
                      body={
                        <>
                          <div className="pl-8 pr-12 pt-6 mb-2 text-sm">
                            For å gi deg en leveringspris må vi vite hvor du
                            ønsker pakken levert. Skriv inn adressen i feltet
                            under og klikk på 'Søk':
                          </div>
                          <Row className="pl-8">
                            <Col sm={8}>
                              <GooglePlacesAutocomplete
                                selectProps={{
                                  deliveryAdress,
                                  onChange: (deliveryAdress: any) => {
                                    setPotentialDeliveryAdress(
                                      deliveryAdress.label
                                    );
                                    handleAddress(deliveryAdress.label);
                                  },
                                }}
                              />
                            </Col>
                            <Col sm={4}>
                              <Button
                                className="bg-gray-600 border-gray-600 hover:bg-[#24292e] hover:border-[#24292e] text-white tracking-wider"
                                onClick={() => {
                                  checkDistanceToPickup(
                                    pickupCoordinates.lat,
                                    pickupCoordinates.lng
                                  );
                                }}
                              >
                                Søk
                              </Button>
                            </Col>
                          </Row>
                        </>
                      }
                    /> */}
                    {/** Modal hvis validering av nummer ikke var korrekt */}
                    <ModalComp
                      show={validateFalseShow}
                      closeModal={() => setValidateFalseShow(false)}
                      title="Ikke korrekt verifiseringskode"
                      hide={() => setValidateFalseShow(false)}
                      footer={
                        <Button
                          variant="secondary"
                          className="text-white bg-[#24292e] text-left ml-16 mt-3"
                          onClick={() => setValidateFalseShow(false)}
                        >
                          Lukk
                        </Button>
                      }
                      body={
                        <>
                          Kode som ble skrevet inn stemte ikke overens med kode
                          som ble sendt på SMS, vennligst prøv igjen.
                        </>
                      }
                    />
                    {/** Modal for å vise om levering er mulig */}
                    <ModalComp
                      show={showDeliveryCoordModal}
                      closeModal={() => setShowDeliveryCoordModal(false)}
                      hide={handleCloseDeliveryCoordModal}
                      title="Valg av levering"
                      footer={
                        <Button
                          variant="secondary"
                          className="text-white bg-[#24292e] text-left ml-16 mt-3"
                          onClick={handleCloseDeliveryCoordModal}
                        >
                          Lukk
                        </Button>
                      }
                      body={
                        <>
                          <div>
                            {closestStorage === "Lørenskog" ? (
                              <div className="pl-8 pr-6 pt-6 mb-2 text-sm">
                                Din adresse er {closestStorageDistance} km unna
                                vårt nærmeste lager som tilbyr levering,{" "}
                                {closestStorage}.
                              </div>
                            ) : null}
                          </div>
                          {closestStorageDistance < 20 ? (
                            <div>
                              <div className="pl-8 pr-6 pt-6 mb-2 text-sm">
                                Vi kan kjøre ut til {deliveryAdress} for{" "}
                                <strong>{leveringPrice},-</strong>.
                                <br />
                                <strong>
                                  Du må selv levere tilbake utstyret
                                </strong>{" "}
                                til vårt lager på{" "}
                                <strong>{closestStorage}</strong> når
                                leieperioden er over.
                              </div>
                              <Button
                                variant="secondary"
                                className="text-black bg-white text-left w-10/12 ml-16 mt-3 mb-8"
                                onClick={() => {
                                  handleCloseDeliveryCoordModal();
                                  setChosePreferredDeliveryTime(true);
                                  setDeliveryPrice(leveringPrice);
                                }}
                              >
                                <div className="pb-2 pt-2 text-black">
                                  <strong>Jeg ønsker kun levering</strong>.
                                </div>
                              </Button>
                              <h2
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  borderBottom: "1px solid #d3d3d3",
                                  lineHeight: "0.1em",
                                  margin: "10px 0 20px",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "0 10px",
                                    background: "#fff",
                                    color: "#808080",
                                  }}
                                  className="font-normal mt-2"
                                >
                                  ELLER
                                </span>
                              </h2>
                              <div className="pl-8 pr-6 pt-6 mb-2 text-sm">
                                Vi både leverer til <strong>{deliveryAdress}</strong>{" "}
                                for <strong>{leveringPrice} ,-</strong> og
                                henter pakken når utleien er over. Henting koster{" "}
                                <strong>{hentingPrice},-</strong> ekstra.
                                Totalsummen for levering og returnering blir{" "}
                                <strong>
                                  {leveringPrice + hentingPrice},-
                                </strong>
                                .
                              </div>
                              <Button
                                variant="secondary"
                                className="text-black bg-white text-left w-10/12 ml-16 mt-3 mb-10"
                                onClick={() => {
                                  handleCloseDeliveryCoordModal();
                                  setChosePreferredDeliveryTime(true);
                                  setDeliveryPrice(
                                    leveringPrice + hentingPrice
                                  );
                                }}
                              >
                                <div className="pb-2 pt-2 text-black">
                                  <strong>
                                    Jeg ønsker både levering og henting
                                  </strong>
                                  .
                                </div>
                              </Button>
                              <h2
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  borderBottom: "1px solid #d3d3d3",
                                  lineHeight: "0.1em",
                                  margin: "10px 0 20px",
                                }}
                              >
                                <span
                                  style={{
                                    padding: "0 10px",
                                    background: "#fff",
                                    color: "#808080",
                                  }}
                                  className="font-normal mt-2"
                                >
                                  ELLER
                                </span>
                              </h2>
                              <Button
                                variant="secondary"
                                className="text-black bg-white text-left w-10/12 ml-16 mt-4 mb-6"
                                onClick={() => {
                                  setDeliveryPrice(0);
                                  handleCloseDeliveryCoordModal();
                                  setCollectionMethod("Hente selv");
                                }}
                              >
                                <div className="pb-2 pt-2 text-black">
                                  <strong>Jeg vil heller hente selvbejent</strong>.
                                </div>
                              </Button>
                            </div>
                          ) : (
                            <>
                              <div className="pl-8 pr-6 pt-6 mb-2 text-sm">
                                <div className="text-red-500">
                                  <strong>
                                    <i className="fas fa-exclamation-triangle" />{" "}
                                    Levering er dessverre ikke mulig til valgt
                                    adresse.
                                  </strong>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      }
                    />
                  </Row>

                  {/** Modal for å fullføre bestilling. Velger om man ønsker forsikring eller ikke */}
                  <ModalComp
                    show={showCompleteOrderModal}
                    closeModal={() => setShowCompleteOrderModal(false)}
                    hide={handleCloseInsuranceModal}
                    title="Fullfør bestilling"
                    footer={
                      
                      <Button
                        variant="secondary"
                        className="text-white bg-[#24292e] text-center ml-16 mt-3"
                        onClick={placeOrderButton}
                      >
                        BESTILL NÅ
                      </Button>
                    }
                    body={
                      <>
                        <div className="text-2xl tracking-wider pb-2 pt-2">
                          Tillegg
                        </div>
                        <div>
                          <div className="text-md tracking-wider pb-2 pt-2 ">
                            Forsikring
                          </div>
                          <div className="text-sm tracking-wider pb-2 pt-2">
                            Dersom du forsikrer din leie trenger du ikke å
                            bekymre deg for at noen ved et uhell ødelegger en
                            gjenstand i pakken. Forsikring koster{" "}
                            <strong>{insurancePrice},-</strong> i tillegg.
                          </div>
                          <Card className="text-black bg-white text-left w-12/12  mt-6 shadow-md border-gray mb-4">
                            <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                              <Row className="mr-4 ml-2">
                                <Col sm={10}>
                                  <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                    Jeg ønsker forsikring for økt trygghet
                                  </div>
                                </Col>
                                <Col sm={2}>
                                  <BootstrapSwitchButton
                                    checked={insurance}
                                    onlabel="Ja"
                                    offlabel="Nei"
                                    width={100}
                                    onstyle="success"
                                    onChange={(e) => {
                                      handleToogle(e);
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        </div>
                        {collectionMethod === "Levering" ? (
                          <div>
                            <div className="text-md tracking-wider pb-2 pt-6 ">
                              Monteringstjeneste
                            </div>
                            <div className="text-sm tracking-wider pb-2 pt-2">
                              Få full service med bæring og montering av din bestilling på rett plass. Vi hjelper deg og sørger for at utstyret monteres og fungerer som ønsket raskt og effektivt. Hvis dere har bestilt henting så demonterer vi også. Monteringstjeneste koster{" "}
                              <strong>{montPrice},- </strong>i tillegg.
                            </div>
                            <Card className="text-black bg-white text-left mb-4 w-12/12  mt-6 shadow-md border-gray">
                              <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                                <Row className="mr-4 ml-2">
                                  <Col sm={10}>
                                    <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                      Jeg ønsker monteringstjeneste
                                    </div>
                                  </Col>
                                  <Col sm={2}>
                                    <BootstrapSwitchButton
                                      checked={montering}
                                      onlabel="Ja"
                                      offlabel="Nei"
                                      width={100}
                                      onstyle="success"
                                      onChange={(e) => {
                                        handleToogleMontering(e);
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </div>
                        ) : null}
                        <div className="text-2xl tracking-wider pb-2 pt-2">
                          Pris
                        </div>
                        <Card className="text-black bg-white text-left w-12/12 border-t-1 border-r-1 border-b-0 border-l-1 rounded-none">
                          <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                            <Row className="mr-4 ml-2">
                              <Col sm={10}>
                                <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                  Pakkepris
                                </div>
                              </Col>
                              <Col sm={2}>
                                <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                  {startPrice + extraDayPrice},-
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                        {addOnPrice !== 0 ? (
                          <div>
                            <Card className="text-black bg-white text-left w-12/12 border-t-1 border-r-1 border-b-0 border-l-1 rounded-none">
                              <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                                <Row className="mr-4 ml-2">
                                  <Col sm={10}>
                                    <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                      Tilleggspris
                                    </div>
                                  </Col>
                                  <Col sm={2}>
                                    <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                      {addOnPrice},-
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </div>
                        ) : null}
                        {rabattVerdi !== 0 ? (
                          <div>
                            <Card className="text-black bg-white text-left w-12/12 border-t-1 border-r-1 border-b-0 border-l-1 rounded-none">
                              <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                                <Row className="mr-4 ml-2">
                                  <Col sm={10}>
                                    <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                      Rabatt
                                    </div>
                                  </Col>
                                  <Col sm={2}>
                                    <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                      {rabattVerdi},-
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </div>
                        ) : null}
                        {deliveryPrice !== 0 ? (
                          <div>
                            <Card className="text-black bg-white text-left w-12/12 border-t-1 border-r-1 border-b-0 border-l-1 rounded-none">
                              <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                                <Row className="mr-4 ml-2">
                                  <Col sm={10}>
                                    <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                      Leveringspris
                                    </div>
                                  </Col>
                                  <Col sm={2}>
                                    <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                      {deliveryPrice},-
                                    </div>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </div>
                        ) : null}
                        {insurance === true ? (
                          <Card className="text-black bg-white text-left w-12/12 rounded-none">
                            <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                              <Row className="mr-4 ml-2">
                                <Col sm={10}>
                                  <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                    Forsikring
                                  </div>
                                </Col>
                                <Col sm={2}>
                                  <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                    {insurancePrice},-
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        ) : null}
                        {montering === true ? (
                          <Card className="text-black bg-white text-left w-12/12 rounded-none">
                            <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                              <Row className="mr-4 ml-2">
                                <Col sm={10}>
                                  <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                    Monteringstjeneste
                                  </div>
                                </Col>
                                <Col sm={2}>
                                  <div className="text-sm tracking-wider pb-2 pt-2 pl-2 text-black">
                                    {monteringPrice},-
                                  </div>
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        ) : null}
                        <Card className="text-black bg-white text-left w-12/12 border-t-4 border-[#24292e] rounded-none">
                          <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                            <Row className="mr-4 ml-2">
                              <Col sm={10}>
                                <div className="text-md tracking-wider pb-2 pt-2 pl-2 text-black">
                                  <div className=" font-bold pb-1 ">
                                    Totalt å betale
                                  </div>
                                  <div className="text-xs">
                                    (Totalsum inkludert mva)
                                  </div>
                                </div>
                              </Col>
                              <Col sm={2}>
                                <div className="text-md tracking-wider pb-2 pt-3 pl-2 text-black font-bold">
                                  {totalPrice},-
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                        <div className="mt-3">
                          <Form>
                            <Form.Check
                              checked={terms}
                              onChange={handleChange(setTerms)}
                              type="checkbox"
                              id="default-checkbox"
                              label={
                                <div>
                                  <span className="text-sm tracking-wider pb-2">
                                    Ved å krysse av godtar du PartyLyd sine {""}
                                    <a
                                      href={
                                        "https://partylyd2022.web.app/leiebetingelser"
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="underline"
                                    >
                                      Leiebetingelser
                                    </a>
                                    <span className="text-red">*</span>
                                  </span>
                                  {termsNotChecked === true ? (
                                    <div className="pt-1 font-normal text-sm tracking-wider text-red-600">
                                      For å legge inn en bestilling må du godta
                                      Leiebetingelsene
                                    </div>
                                  ) : null}
                                </div>
                              }
                            />
                          </Form>
                          <div style={{ color: 'red', marginTop: '16px' }}>
                            OBS! Våre lagre er selvbetjente. Dette betyr at det er ingen som legger klart utstyret for deg. Alt utstyret må plukkes selv på lageret. Dette gjør vi for å kutte kostnader slik at vi kan tilby utstyret til en rimeligere pris. Det kan derfor være lurt å beregne litt ekstra tid ved henting.
                          </div>
                        </div>
                      </>
                    }
                  />

                  {/** Modal for å vertifisere mobilnummer før man fullfører bestilling */}
                  <ModalComp
                    show={validateShow}
                    closeModal={() => setValidateShow(false)}
                    title="Verifisering"
                    hide={() => setValidateShow(false)}
                    footer={
                      <Button
                        variant="secondary"
                        className="text-white bg-[#24292e] text-left ml-16 mt-3"
                        onClick={() => setValidateShow(false)}
                      >
                        Lukk
                      </Button>
                    }
                    body={
                      <>
                        <InputGroup>
                          <Container className="mt-6 mb-6">
                            <Row>
                              <div className="text-md tracking-wider font-weight-400">
                                Vi sendte nettopp en kode til{" "}
                                <strong>{phoneNumber}</strong>: (vent inntil 30
                                sek)
                              </div>
                              <Col
                                lg={{ span: 8, order: 0 }}
                                xs={{ span: 12 }}
                                className="mt-4"
                              >
                                <FormControl
                                  placeholder="Kode (6 siffer)"
                                  aria-describedby="verify-code"
                                  onChange={(e) =>
                                    setVerifyInputCode(Number(e.target.value))
                                  }
                                />
                              </Col>
                              <Col
                                lg={{ span: 3, order: 0 }}
                                xs={{ span: 12 }}
                                className="mt-4"
                              >
                                <Button
                                  className={`tracking-wide w-100 ${areFieldsFilledVerifyCode}`}
                                  style={{
                                    backgroundColor: "#e57e22",
                                    borderColor: "#e57e22",
                                    fontWeight: 400,
                                  }}
                                  onClick={checkVerifyCode}
                                >
                                  Bekreft
                                </Button>
                              </Col>
                            </Row>
                          </Container>
                        </InputGroup>
                      </>
                    }
                  />
                  {/** Telefonnummer inputfelt */}
                  <Row>
                    <div className="pl-16 pt-8 font-normal text-sm tracking-wider whitespace-pre">
                      Ditt telefonnummer
                    </div>
                    <Card
                      className={`text-black bg-white text-left w-8/12 ml-16 mt-3 shadow-md border-${inputStylePhone}`}
                    >
                      <Card.Body className="pt-2 pr-1 pl-1 pb-2">
                        <div className="text-xs text-light pb-2 text-black">
                          DITT TELEFONNUMMER{" "}
                        </div>
                        <input
                          className="w-100 pb-2"
                          value={phoneNumber}
                          onChange={(e) => setPhoneNumber(e.target.value)}
                          placeholder="Skriv inn ditt telefonnummer"
                        ></input>
                      </Card.Body>
                    </Card>
                    {phoneNumberChosen === true ? (
                      <div className="pl-16 pt-1 font-normal text-sm tracking-wider text-red-600">
                        Du må fylle inn telefonnummer, eksempel:{" "}
                        <em>12345678</em>
                      </div>
                    ) : null}
                  </Row>
                  <Row>
                    <Button
                      variant="secondary"
                      className={`bg-[#24292e] text-white tracking-wider w-8/12 ml-16 mt-8 rounded-md pt-3 pb-3 ${areFieldsFilled}`}
                      onClick={checkAvaliabilityOfPackage}
                    >
                      NESTE
                    </Button>
                  </Row>
                  <Row>
                    <div className="ml-14 w-8/12 pt-4 font-normal text-sm tracking-wider pb-3">
                      Lurer du på noe? Sjekk ut vår{" "}
                      {
                        <b>
                          <a href="https://partylyd.no/faq"> FAQ-side! </a>
                        </b>
                      }{" "}
                      Har du andre spørsmål om bestilling? Kontakt oss eller ta
                      en prat med vår <strong>chatbot</strong>.
                    </div>
                  </Row>
                  <Row>
                    <>
                      <div
                        className="ml-14 w-8/12 pt-4 font-normal text-sm tracking-wider pb-2 cursor-pointer"
                        onClick={(e) =>
                          setRabattkodeVisibility(!rabattkodeVisibility)
                        }
                      >
                        Har du en rabattkode?
                      </div>
                      <hr
                        style={{
                          display: "block",
                          height: "2px",
                          border: "0",
                          borderTop: "1px solid #111",
                          width: "68%",
                          marginLeft: "4em",
                          marginTop: "0em",
                          padding: "0",
                        }}
                      />
                    </>
                  </Row>
                  {rabattkodeVisibility ? (
                    <>
                      <div className="ml-16 mt-3 font-normal text-sm tracking-wider">
                        Legg til etter leieperiode og tillegg
                      </div>
                      <Row>
                        <div className="flex ml-16 mt-1">
                          <Card className="w-5/12">
                            <Card.Body>
                              <input
                                value={inputRabattkode}
                                onChange={(e) => {
                                  setInputRabattkode(e.target.value);
                                  if (e.target.value === "") {
                                    setIsValid(null);
                                    setValidationMessage("");
                                  }
                                }}
                                className="w-32"
                                placeholder="Skriv rabattkode"
                              />
                            </Card.Body>
                          </Card>

                          <Button
                            variant={isValid ? "success" : "danger"}
                            className={`text-white rounded-md ml-3 w-3/12 ${
                              isValid === null
                                ? "bg-[#e57e22] border-[#e57e22] hover:bg-[#FFA554] hover:border-[#FFA554] focus:bg-[#e57e22] focus:border-[#e57e22] active:bg-[#FFA554] active:border-[#e57e22]"
                                : isValid
                                ? "bg-success border-success hover:bg-success hover:border-success focus:bg-success focus:border-success active:bg-success active:border-success"
                                : "bg-danger border-danger hover:bg-danger hover:border-danger focus:bg-danger focus:border-danger active:bg-danger active:border-danger"
                            }`}
                            onClick={checkRabattkode}
                          >
                            {isValid === null ? (
                              "Aktiver"
                            ) : isValid ? (
                              <div className="flex items-center ml-11">
                                <IoCheckmark />
                              </div>
                            ) : (
                              <div className="flex items-center ml-11">
                                <IoClose />
                              </div>
                            )}
                          </Button>
                        </div>

                        {validationMessage && (
                          <div className="text-red-500 ml-16 mt-1">
                            {validationMessage}
                          </div>
                        )}
                      </Row>
                    </>
                  ) : null}
                  {rabattFeilKode !== "" && rabattkodeVisibility ? (
                    <div className="ml-16 font-medium text-sm tracking-wider pb-2 pt-1 text-red">
                      {rabattFeilKode}
                    </div>
                  ) : null}
                </Col>
              </Col>
            </Row>
          </Container>
        </>
    </div>
  );
};
export default Packages;
