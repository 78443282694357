import { FC, ReactNode } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { FaFacebookSquare, FaLinkedin } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

//@ts-ignore
import logo_uten_tekst from "../img/LogoUtenTekst.png";
import "../style/footer.css";

interface FooterProps {}

const Footer: FC<FooterProps> = ({}) => {
  return (
    <>
      <div className="bg-dark text-white main-footer -pb-10 font-josefin">
        <Row>
          <Col md={{ span: 2, offset: 1 }}>
            <div className="ml-6">
              <Col className="mt-8 mb-4">
                <div className="font-bold text-xl text-[#e57e22] tracking-wider font-bold mb-1">
                  PartyLyd.no
                </div>
                <div className="font-medium text-lg">Lyd og Lysutleie</div>
              </Col>
              <div className="flex">
                <Nav.Item className="">
                  <Nav.Link
                    href="https://www.facebook.com/partylyd.no"
                    className="pb-2"
                  >
                    <FaFacebookSquare size={30} className="mr-2" />
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    href="https://www.linkedin.com/company/partylyd-as/about/"
                    className="pb-2"
                  >
                    <FaLinkedin size={30} />
                  </Nav.Link>
                </Nav.Item>
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className=" sm:px-12 px-6 py-7">
              <h1 className="text-2xl pb-3">Om oss</h1>
              <div className="pb-2">PARTYLYD AS</div>
              <div className="pb-2">
                Lørenskog, Asker, Vinterbro, Stavanger, Kristiansand, Trondheim og Bergen
              </div>
              <div className="pb-2">Telefon nr. +47 61 60 94 90</div>
              <div className="pb-2">Åpningstider Telefon</div>
              <div className="pb-2">Man-Fre: 17.00 - 20.30</div>
              <div className="pb-2">Lør-Søn: 10.00 - 20.00</div>
              <div className="pb-2">Org. nr. 911 978 563</div>
              <div className="pb-2">
                <a>kontakt@partylyd.no</a>
              </div>
            </div>
          </Col>
          <Col md={2}>
            <div className="sm:px-12 px-4 py-7">
              <h1 className="text-2xl  pb-3">Hjelp</h1>
              <Nav.Item className="">
                <Nav.Link href="/aktuelt" className="pb-2">
                  Aktuelt
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className="">
                <Nav.Link href="/finn-min-bestilling" className="pb-2">
                  Min bestilling
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/kontakt" className="pb-2">
                  Kontakt oss
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/leiebetingelser" className="pb-2">
                  Leiebetingelser
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="/hvor-finner-du-oss" className="pb-2">
                  Her finner du oss
                </Nav.Link>
              </Nav.Item>
            </div>
          </Col>
          <Col md={3}>
            <div className=" sm:px-12 px-6 py-7">
              <h1 className="text-2xl pb-3">Innlogging</h1>
              <Nav.Item className="">
                <Nav.Link href="/logg-inn" className="pb-2">
                  Logg inn
                </Nav.Link>
              </Nav.Item>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Footer;
